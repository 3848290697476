<template>
  <div>
    <div class="breadCurmb mt-3">
  <label>
    <router-link to="/">{{ $t("Home") }}</router-link>
  </label>
  <span>/</span>
  <label active>{{ $t("WorkGroup") }}</label>
  <span>/</span>
  <label v-if="fromPage == 'subscribes'">
    <router-link :to="{ name: 'subscribe' }">{{ $t("Subscribes") }}</router-link>
  </label>
  <label v-else-if="fromPage == 'active_subscribes'">
    <router-link :to="{ name: 'subscribe', query: { type: 'active' } }">{{ $t("ActiveSubscribes") }}</router-link>
  </label>
  <label v-else-if="fromPage == 'stop_subscribes'">
    <router-link :to="{ name: 'subscribe', query: { type: 'stop' } }">{{ $t("StopSubscriptions") }}</router-link>
  </label>
  <label v-else-if="fromPage == 'expired_subscribes'">
    <router-link :to="{ name: 'subscribe', query: { type: 'expired' } }">{{ $t("ExpiredSubscriptions") }}</router-link>
  </label>
  <label v-else-if="fromPage == 'removed_subscribes'">
    <router-link :to="{ name: 'subscribe', query: { type: 'removed' } }">{{ $t("RemovedSubscriptions") }}</router-link>
  </label>
  <label v-else-if="fromPage == 'customers'">
    <router-link :to="{ name: 'customers' }">{{ $t("Customers") }}</router-link>
  </label>
  <span>/</span>
  <label active>{{ $t("CustomerDetails") }}</label>
</div>


    <v-container fluid>
      <h2>{{ $t("CustomerDetails") }}</h2>
      <v-row class="mt-0" v-if="details && details.customer">
        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>{{ $t('SubscriberName') }}: 
              <span> {{ details.customer.name }} </span>
            </p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>{{ $t('Mobile') }}: 
                <span> {{ details.customer.mobile }} </span>
            </p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>{{ $t('Email') }}: 
              <span>{{ details.customer.email }}</span>
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="details">

        <v-col cols="12">
          <div class="customCard" style="background: #342b54; color: #fff">
            <p
              style="
                display: inline-block;
                margin: 0 15px;
                position: absolute;
                right: 30px;
              "
            >
              {{ $t('TotalCount') }}: {{ details.subscription_count }}
            </p>
            <p style="display: inline-block; text-align: center !important">
             {{ $t('CustomerSubscribeData') }}
            </p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="">
            <v-autocomplete
              style="width: 100%"
              class="d-block my-2"
              name="subsctiption"
              v-validate="''"
              data-vv-as="الاشتراكات"
              hide-details
              dense
              outlined
              v-model="filters.subsctiption"
              item-value="id"
              item-text="name"
              :items="details.subscriptions"
              @change="changeDetailsCustomer(filters.subsctiption)"
              clearable
              placeholder="اختر اشتراك"
            ></v-autocomplete>
          </div>
        </v-col>

        <template v-if="detailsCustomer">

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>تاريخ بداية الاشتراك: <span dir="ltr">
              {{ detailsCustomer.subscribe.start_date }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>تاريخ نهاية الاشتراك: <span dir="ltr">
                {{ detailsCustomer.subscribe.end_date }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>البروتين: <span>
              {{ detailsCustomer.personal_desire.protein }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الكارب: <span>
              {{ detailsCustomer.personal_desire.carbs }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الاستثناءات: <span>
               {{ detailsCustomer.excluded_main_ingredients_and_not_main_ingredients }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الباقة: <span>
               {{ detailsCustomer.package.name }}
              </span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>مميزات الباقة : 
              <span> {{ detailsCustomer.group_subscription }}</span>
            </p>
          </div>
        </v-col>

       
        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>حالة الاشتراك: <span>
                {{ detailsCustomer.subscribe.active }}
              </span></p>
          </div>
        </v-col>


         <v-col cols="12">
          <div class="customCard" style="height:auto;">
            <p> ملاحظات 
              <span> {{ detailsCustomer.personal_desire.notes }}</span>
            </p>
          </div>
        </v-col>


        

          <template v-if="detailsCustomer.transaction">

              <v-col cols="12">
                <div class="customCard" style="background: #2B542E; color: #fff">
                  <p>عمليات الدفع  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <div class="customCard">
                  <p>مبلغ الدفع: <span>
                  {{ detailsCustomer.transaction.amount }}
                </span></p>
                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <div class="customCard">
                  <p>تاريخ الدفع: <span>
                  {{ detailsCustomer.transaction.parse_created_at }}
                </span></p>
                </div>
              </v-col>


              <v-col cols="12" sm="12">
                <div class="customCard">
                  <p>الرقم المرجعي للدفع: <span>
                  {{ detailsCustomer.transaction.transaction_id }}
                </span></p>
                </div>
              </v-col>
          </template>



        <v-col cols="12">
          <div class="customCard" style="background: #2B542E; color: #fff">
            <p>القياسات الحالية</p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الطول: <span>{{ detailsCustomer.measurement.height }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الوزن: <span>{{ detailsCustomer.measurement.weight }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>العضلات: <span>{{ detailsCustomer.measurement.muscle }}</span></p>
          </div>
        </v-col>

        <!-- <v-col cols="12">
          <div class="customCard" style="background: #2B542E; color: #fff">
            <p
              style="
                display: inline-block;
                margin: 0 15px;
                position: absolute;
                right: 30px;
              "
            >
              إنقاص وزن
            </p>
            <p style="display: inline-block; text-align: center !important">
              goal life cycle
            </p>
          </div>
        </v-col>

        <timeline /> -->
        

        </template>

      </v-row>

      <v-row>

        <template  v-if="detailsCustomer">

        <v-col cols="12" sm="6">
          <div class="customCard" style="background: green; color: #fff">
            <p>قائمة عمليات الإيقاف والاستئناف خلال الاشتراك</p>
          </div>
        </v-col>

        
        <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="12" sm="6">
          <table id="stopContinueSubscribe">
            <tr>
              <th>رقم العملية</th>
              <th>تاريخ الإيقاف</th>
              <th>تاريخ الاستئناف</th>
            </tr>

            <template v-if="detailsCustomer.subscription_suspension_resumptions.length>0">
            <tr v-for="(d,index) in detailsCustomer.subscription_suspension_resumptions" :key="index">
              <td>{{d.id}}</td>
              <td>{{d.end_date}}</td>
              <td>{{d.start_date}}</td>
            </tr>
            </template>
            <template v-else>
              <tr 
              >
              <td colspan="3" style="color:red;">لا توجد بيانات</td> 
              </tr>
            </template>

          </table>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="customCard" style="background: green; color: #fff">
            <p>قائمة التعويضات خلال الاشتراك</p>
          </div>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" sm="6">
          <table id="stopContinueSubscribe">
            <tr>
              <th>رقم العملية</th>
              <th>تاريخ الإيقاف</th>
              <th>تاريخ الاستئناف</th>
            </tr>

            <template v-if="detailsCustomer.subscription_suspension_resumptions.length>0">
            <tr v-for="(d,index) in detailsCustomer.subscription_suspension_resumptions" :key="index">
              <td>{{d.id}}</td>
              <td>{{d.end_date}}</td>
              <td>{{d.start_date}}</td>
            </tr>
            </template>
            <template v-else>
              <tr 
              >
              <td colspan="3" style="color:red;">لا توجد بيانات</td> 
              </tr>
            </template>

          </table>
        </v-col>

        <v-col cols="12" sm="6">
          <table id="stopContinueSubscribe">
            <tr>
              <th>عدد أيام التعويض</th>
              <th>تاريخ التعويض</th>
              <th>سبب التعويض</th>
            </tr>

            <template v-if="detailsCustomer.subscription_compensations.length>0">
            <tr v-for="(t,index) in detailsCustomer.subscription_compensations" :key="index">
              <td>{{t.number_of_days}}</td>
              <td>{{t.date}}</td>
              <td>{{t.reason}}</td>
            </tr>
            </template>
            <template v-else>
              <tr 
              >
              <td colspan="3" style="color:red;">لا توجد بيانات</td> 
              </tr>
            </template>
          </table>
        </v-col>

        </template>

      </v-row>
       
        <v-row v-if="detailsCustomer">
        <v-col cols="12">
          <div class="customCard" style="background: #342b54; color: #fff">
            <p>معلومات التوصيل</p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>المدينة: <span>{{ detailsCustomer.delivery.city }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الحي: <span>{{ detailsCustomer.delivery.branch  }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>فترة التوصيل: <span>{{ detailsCustomer.delivery.period  }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الجهة / الشركة: <span>{{ detailsCustomer.delivery.company  }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>طريقة التسليم: <span>{{ detailsCustomer.delivery.notes }}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>معدل وقت الوصول: <span>{{detailsCustomer.delivery.avg_arrival_time}}</span></p>
          </div>

        </v-col>
        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>اسم المندوب: <span>{{detailsCustomer.delivery.driver_name}}</span></p>
          </div>
        </v-col>

         <v-col cols="12" sm="4">
          <div class="customCard">
            <p>رقم الجوال: <span>{{detailsCustomer.delivery.driver_mobile}}</span></p>
          </div>
        </v-col>

          <v-col cols="12" sm="4">
          <div class="customCard">
            <p>مجموعات التغليف: <span>{{detailsCustomer.packaging_group_city_period}}</span></p>
          </div>
        </v-col>

        <v-col cols="12">
          <table id="delivarData5">
            <tr>
              <th> # </th>
              <th>تاريخ اليوم</th>
              <th>اسم اليوم</th>
              <th>المدينة - الحي</th>
              <th>معاد التوصيل</th>
              <th>المدينة - الحي</th>
              <th>وقت التسليم</th>
              <th>حالة التسليم</th>
              <th>سبب إلغاء التسليم إن وجد</th>
            </tr>

            <tr v-for="(d, dIndex) in detailsCustomer.subscription_data" :key="dIndex">
              <td>{{dIndex + 1}}</td>
              <td>{{d.date}}</td>
              <td>{{ d.day_name }}</td>
              <td>{{ d.city_with_branch }}</td>
              <td>{{ d.period_translation }}</td>
              <td>{{ d.city_with_branch }}</td>
              <td>{{d.deliver_time}}</td>
              <td>
                {{d.driver_deliver_text}}
            </td>
              <td>{{d.reason_of_not_delivary}}</td>
            </tr>
          </table>
        </v-col>

        </v-row>

        <v-row v-if="detailsCustomer">
        <v-col cols="12">
          <div class="customCard" style="background: #342b54; color: #fff">
            <p>معلومات الرصيد</p>
          </div>
        </v-col>

        <!-- <v-col cols="12" sm="4">
          <div class="">
            <v-autocomplete
              style="width: 100%"
              class="d-block my-2"
              name="subsctiption"
              v-validate="''"
              data-vv-as="الاشتراك"
              hide-details
              dense
              outlined
              v-model="filters.subsctiption2"
              item-value="id"
              item-text="name"
              :items="subscriptionData"
               @change="changeRaseedCustomer(filters.subsctiption2)"
              clearable
            ></v-autocomplete>
          </div>
        </v-col> -->

        <template>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الرصيد الإجمالي (شامل التعويض): <span>{{detailsCustomer.subscribe.num_of_days}}</span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="customCard">
            <p>الرصيد المتبقي: <span>{{ detailsCustomer.subscribe.remind_days }}</span></p>
          </div>
        </v-col>


        <!-- <v-col cols="12">
          <table id="delivarData2">
            <tr>
              <th>تاريخ اليوم</th>
              <th>اسم اليوم</th>
              <th>العملية</th>
              <th>الرصيد المتبقي</th>
            </tr>

             <template>
            <tr v-for="(it,index) in raseedCustomer.items" :key="index">
              <td>date here</td>
              <td>day here</td>
              <td>operation</td> 
              <td>reaseed here</td>
            </tr>
            </template>
            <template>
              <tr 
              >
              <td colspan="4" style="color:red;">لا توجد بيانات</td> 
              </tr>
            </template>

          </table>
        </v-col> -->
        <!-- :style="it.active_operation?'background: #2ba8c4; color: #fff':''" -->

        </template>


        
        <v-col cols="12" v-if="detailsCustomer">
          <div class="customCard" style="background: #342b54; color: #fff">
            <p>الشكاوي</p>
          </div>
        </v-col>

        <!-- <v-col cols="12" sm="4">
          <div>
            <el-date-picker
              style="width: 100%"
              v-model="filters.from_date_two"
              type="date"
              class="mt-2"
              placeholder="تاريخ بداية الفترة"
              name="from_date_two"
              data-vv-scope="addEditValidation"
              v-validate="''"
              :data-vv-as="$t('FromDate')"
              hide-details
              dense
              outlined
               @change="changeCompliantsData(filters.from_date_two, filters.to_date_two)"

            >
            </el-date-picker>
          </div>
        </v-col> -->

          <!-- showCompliantsInfo -->
          <v-col cols="12" sm="4" v-if="detailsCustomer"
          >
          <div class="customCard">
            <p>عدد إجمالي الشكاوي: <span>{{detailsCustomer.complaints.data.length}}</span></p>
          </div>
        </v-col>

        
        <!-- <v-col cols="12" sm="4">
          <div>
            <el-date-picker
              style="width: 100%"
              v-model="filters.to_date_two"
              type="date"
              class="mt-2"
              placeholder="تاريخ نهاية الفترة"
              name="to_date_two"
              data-vv-scope="addEditValidation"
              v-validate="''"
              :data-vv-as="$t('ToDate')"
              hide-details
              dense
              outlined
               @change="changeCompliantsData(filters.from_date_two, filters.to_date_two)"
            >
            </el-date-picker>
          </div>
        </v-col> -->

         <!-- <v-col cols="12" sm="4" v-if="detailsCustomer">
          <div class="customCard">
            <p>عدد شكاوي التوصيل: <span></span></p>
          </div>
        </v-col>

        
         <v-col cols="12" sm="4"  v-if="detailsCustomer">
          <div class="customCard">
            <p>عدد شكاوي المطبخ: <span></span></p>
          </div>
        </v-col>

        <v-col cols="12" sm="4"  v-if="detailsCustomer">
          <div class="customCard">
            <p>عدد شكاوي التغليف: <span></span></p>
          </div>
        </v-col> -->

         <v-col cols="12" v-if="detailsCustomer">
          <table id="delivarData5">
            <tr>
              <th>تاريخ اليوم</th>
              <th>اسم اليوم</th>
              <th>قسم الشكوى</th>
              <th>تصنيف الشكوى</th>
              <th>نص الشكوى</th>
            </tr>

             <template  v-if="detailsCustomer.complaints.data.length>0">
            <tr v-for="(cmp, index) in detailsCustomer.complaints.data" :key="index">
              <td>{{cmp.date}}</td>
              <td>{{ cmp.day_name }}</td>
              <td>{{ cmp.division_name }}</td>
              <td>{{ cmp.complaint_type_name}}</td>
              <td>{{ cmp.notes}}</td>
            </tr>
            </template>
            
            
            <template v-else >
              <tr>
                <td colspan="5" style="color:red">لا توجد بيانات</td>
              </tr>
            </template>


          </table>
        </v-col>

        <!-- <v-col cols="3"> 
          <div @click="goTo('all')" class="customCard" style="background:#02f;color:#fff;cursor:pointer;">
            <p>عدد الاشتراكات الكلي: <span>10</span></p>
          </div>
           </v-col>

           <v-col cols="3"> 
          <div @click="goTo('active')" class="customCard" style="background:#5d5;color:#fff;cursor:pointer;">
            <p>عدد الاشتراكات الفعالة: <span>5</span></p>
          </div>
           </v-col>

            <v-col cols="3"> 
          <div @click="goTo('stop')" class="customCard" style="background:#f11;color:#fff;cursor:pointer;">
            <p>عدد الاشتراكات الموقوفة: <span>2</span></p>
          </div>
           </v-col>

           <v-col cols="3"> 
          <div @click="goTo('expired')" class="customCard" style="background:#fa1;color:#fff;cursor:pointer;">
            <p>عدد الاشتراكات المنتهية: <span>3</span></p>
          </div>
           </v-col> -->


            <v-col cols="12">
          <div class="customCard" style="background: #342b54; color: #fff">
            <p>معلومات الأصناف</p>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <!-- <div class="customCard" > -->
          <!-- <p>تاريخ بداية الفترة: <span>20/02/2022</span></p> -->
          <!-- </div> -->

          <div>
            <el-date-picker
              style="width: 100%"
              v-model="filters.from_date"
              type="date"
              class="mt-2"
              name="from_date"
              data-vv-scope="addEditValidation"
              v-validate="''"
              :data-vv-as="$t('Date')"
              hide-details
              placeholder="تاريخ البحث"
              dense
              outlined
              @change="changeItmesData()"
            >
            </el-date-picker>
          </div>
        </v-col>
        </v-row>

        <!-- <v-col cols="12" sm="4"> </v-col> -->

        <!-- <v-col cols="12" sm="4">
          <div>
            <el-date-picker
              style="width: 100%"
              v-model="filters.to_date"
              type="date"
              class="mt-2"
              placeholder="تاريخ نهايةالفترة"
              name="to_date"
              data-vv-scope="addEditValidation"
              v-validate="''"
              :data-vv-as="$t('ToDate')"
              hide-details
              dense
              outlined
              @change="changeItmesData(filters.from_date, filters.to_date)"
            >
            </el-date-picker>
          </div>
        </v-col> -->

        <v-row v-if="showCatInfo">

        <v-col cols="12" sm="4" >
          <table id="delivarData5">
            <tr>
              <th>وجبة البروتين</th>
              <th>عدد وجبة البروتين</th>
            </tr>

            <template  v-if="protinInfo.length>0">
            <tr v-for="(itf, index) in protinInfo" :key="index">
              <td>{{ itf.recipie_protein_name}}</td>
              <td>{{ itf.protein_count}}</td>
            </tr>
            </template>
            
            <template v-else >
              <tr>
                <td colspan="5" style="color:red">لا توجد بيانات</td>
              </tr>
            </template>
          </table>
        </v-col>

          <v-col cols="12" sm="4">
          <table id="delivarData5">
            <tr>
              <th>وجبة الكارب</th>
              <th>عدد وجبة الكارب</th>
            </tr>

            <template  v-if="carbInfo.length>0">
            <tr v-for="(itf, index) in carbInfo" :key="index">
              <td>{{ itf.recipie_carb_name}}</td>
              <td>{{ itf.carb_count}}</td>
            </tr>
            </template>
            
            <template v-else >
              <tr>
                <td colspan="5" style="color:red">لا توجد بيانات</td>
              </tr>
            </template>
          </table>
        </v-col>

         <v-col cols="12" sm="4">
          <table id="delivarData5">
            <tr>
              <th>اسم الميزة</th>
              <th>العدد</th>
            </tr>

            <template  v-if="groupMenuCookingDailiesInfo.length>0">
            <tr v-for="(itf, index) in groupMenuCookingDailiesInfo" :key="index">
              <td>{{ itf.recipie_name}}</td>
              <td>{{ itf.quantity}}</td>
            </tr>
            </template>
            
            <template v-else >
              <tr>
                <td colspan="5" style="color:red">لا توجد بيانات</td>
              </tr>
            </template>
          </table>
        </v-col>

        </v-row>



        <v-row>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import timeline from "./timeline.vue";
export default {
  name: "subscribeDetails",
  components: {
    timeline,
  },
  data() {
    return {
      fromPage: '',
      showCatInfo: false,
      detailsCustomer: null,
      raseedCustomer: null,
      protinInfo: [],
      carbInfo: [],
      groupMenuCookingDailiesInfo: [],
      compliantsInfo: [],
      showCompliantsInfo: false,
      id: this.$route.params.id || null,
      details: null,
      filters: {
        subsctiption: null,
        subsctiption2: null,
        from_date: null,
        to_date: null,
        from_date_two: null,
        to_date_two: null,
      },
      subscriptionData: [
        {
          id: 1,
          name: "اشتراك 1",
        },
        {
          id: 2,
          name: "اشتراك 2",
        },
        {
          id: 3,
          name: "اشتراك 3",
        },
      ],
      compliantsInfoDetails: {
        count:0,
        count1:0,
        count2:0,
        count3:0,
      },
    };
  },
  methods: {
    changeItmesData() {

      this.showCatInfo= false;
       this.protinInfo= [];
       this.carbInfo= [];
       this.groupMenuCookingDailiesInfo= [];
       let sendData = {
        date: moment(this.filters.from_date).format('yyyy-MM-DD'),
        id: this.filters.subsctiption
       }

      if(this.filters.from_date) {
        this.$store.dispatch(`customer/fetchCookingDailyWithDetails`, sendData).then(res => {
          this.showCatInfo= true;
            if(res.data && res.data.proteinCookingDailies) {
              this.protinInfo = res.data.proteinCookingDailies;
            }
             if(res.data && res.data.carbCookingDailies) {
              this.carbInfo = res.data.carbCookingDailies;
            }
             if(res.data && res.data.groupMenuCookingDailies) {
              this.groupMenuCookingDailiesInfo = res.data.groupMenuCookingDailies;
            }
       })
      }

      
    },
    changeCompliantsData(fdate,tdate) {
      //  this.compliantsInfo= [];
      //   this.compliantsInfoDetails = {
      //     count: 0,
      //     count1: 0,
      //     count2: 0,
      //     count3: 0,
      //   }
      // this.showCompliantsInfo= true;
      // if (fdate != null && moment(fdate).format('DD-MM-yyyy') == '10-03-2023') {
      //   this.compliantsInfoDetails = {
      //     count: 1,
      //     count1: 0,
      //     count2: 1,
      //     count3: 0,
      //   }
      //   this.compliantsInfo= [
      //     {
      //       date: '10-03-2023',
      //       day: 'السبت',
      //       division: 'المطبخ',
      //       category: 'الأكل غير مستوي',
      //       details: 'وجبة اليوم كانت برجر وواضح أن اللحم غير مستوي',
      //     },
      //   ];
      // }
      // else if(fdate != null || tdate != null) {
      //    this.compliantsInfoDetails = {
      //     count: 3,
      //     count1: 2,
      //     count2: 0,
      //     count3: 1,
      //   }
      //   this.compliantsInfo= [
      //      {
      //       date: '10-03-2023',
      //       day: 'السبت',
      //       division: 'المطبخ',
      //       category: 'الأكل غير مستوي',
      //       details: 'وجبة اليوم كانت برجر وواضح أن اللحم غير مستوي',
      //     },
      //      {
      //       date: '11-03-2023',
      //       day: 'الأحد',
      //       division: 'المطبخ',
      //       category: 'نقص عدد الأصناف',
      //       details: 'لم يصلني فطور اليوم',
      //     },
      //      {
      //       date: '14-03-2023',
      //       day: 'الاثنين',
      //       division: 'المطبخ',
      //       category: 'نقص في ملح الطعام',
      //       details: 'الطعام مالح',
      //     }
      //   ];
      // }else {
      //   this.compliantsInfo= [];
      // }
    },
    changeDetailsCustomer(customerID) {
      this.detailsCustomer= null;
      if(customerID) {
        this.$store.dispatch(`customer/getSubscription`, customerID).then(res =>{
        this.detailsCustomer = res.data;
      })
      }
   
      // if(customer == 1) {
      //   this.detailsCustomer= {
      //     start_subscribe: '20/02/2022',
      //     end_subscribe: '20/03/2023',
      //     protin: '15',
      //     except: '20',
      //     carb: '10',
      //     package: 'باقة رقم 1',
      //     features: 'هذه باقة طويلة الأمد',
      //     status: 'فعال',
      //     stopItems: [
      //       {
      //         id: 'العملية 2',
      //         stop_date: '12/02/2023',
      //         continue_date: '17/02/2023',
      //       },
      //       {
      //         id: 'العملية 5',
      //         stop_date: '15/02/2023',
      //         continue_date: '25/02/2023',
      //       }
      //     ],
      //     taweedItems: []
      //   }
      // }
      // else if(customer == 2) {
      //   this.detailsCustomer= {
      //     start_subscribe: '15/02/2022',
      //     end_subscribe: '18/03/2023',
      //     protin: '5',
      //     except: '15',
      //     carb: '20',
      //     package: 'باقة رقم 2',
      //     features: 'هذه باقة قصيرة الأمد',
      //     status: 'معطل',
      //     stopItems: [],
      //      taweedItems: [
      //       {
      //         days : 3,
      //         date: '12/02/2023',
      //         reason: 'التأخر بالتوصيل',
      //       },
      //       {
      //         days : 1,
      //         date: '14/02/2023',
      //         reason: 'هدية من المطعم',
      //       },
      //      ]
      //   }
      // }
      // else if(customer == 3) {
      //   this.detailsCustomer= {
      //   start_subscribe: '10/02/2022',
      //   end_subscribe: '07/05/2023',
      //   protin: '30',
      //   except: '50',
      //   carb: '70',
      //   package: 'باقة رقم 3',
      //   features: 'هذه باقة متوسطة الأمد',
      //   status: 'فعال',
      //   stopItems: [
      //       {
      //         id: 'العملية 4',
      //         stop_date: '04/03/2023',
      //         continue_date: '28/04/2023',
      //       }
      //     ],
      //     taweedItems: [
      //       {
      //         days : 2,
      //         date: '15/02/2023',
      //         reason: 'الأكل بارد',
      //       },
      //      ]
      // };
      // }
    },
    changeRaseedCustomer(customer) {
      
      this.raseedCustomer= null;

      if(customer == 1) {
        this.raseedCustomer= {
          shamel: 200,
          remain: 50,
          items: [
            {
              date: '12/02/2023',
              day: 'الأحد',
              operation: '-1',
              raseed: '23',
              active_operation: false,
            },
            {
              date: '12/03/2023',
              day: 'الاثنين',
              operation: '2',
              raseed: '25',
              active_operation: true,
            },
            {
              date: '12/04/2023',
              day: 'الثلاثاء',
              operation: '-1',
              raseed: '24',
              active_operation: false,
            },
          ],
        }
      }
      else  if(customer == 2) {
        this.raseedCustomer= {
          shamel: 70,
          remain: 8,
          items: [],
        }
      }
      else if(customer == 3) {
        this.raseedCustomer= {
          shamel: 100,
          remain: 20,
          items: [
            {
              date: '07/02/2023',
              day: 'السبت',
              operation: '2',
              raseed: '23',
              active_operation: true,
            },
            {
              date: '12/04/2023',
              day: 'الأربعاء',
              operation: '2',
              raseed: '25',
              active_operation: true,
            },
          ],
        }
      }

    },
    goTo(routeName) {
      if (routeName == "all") {
        this.$router.push(
          "/admin/subscribe?type=&customer_name=&customer_mobile=&city_id=&branch_id="
        );
      } else if (routeName == "active") {
        this.$router.push(
          "/admin/subscribe?type=active&customer_name=&customer_mobile=&city_id=&branch_id="
        );
      } else if (routeName == "stop") {
        this.$router.push(
          "/admin/subscribe?type=stop&customer_name=&customer_mobile=&city_id=&branch_id="
        );
      } else if (routeName == "expired") {
        this.$router.push(
          "/admin/subscribe?type=expired&customer_name=&customer_mobile=&city_id=&branch_id="
        );
      }
    },
  },
  created() {
    let  customer_details_from= localStorage.getItem('customer_details_from') || 'customers';
    this.fromPage= customer_details_from;

    if (this.$route.params.id) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$store
        .dispatch(`customer/findData`, this.$route.params.id)
        .then((res) => {
          this.details = res.data;
          loading.close();
        });
    }
  },
};
</script>


<style>
.subscribe_details_table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.subscribe_details_table td,
.subscribe_details_table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.subscribe_details_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #8f9e06 !important;
  color: white;
}
.subscribe_details_table2 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #0a9ca1 !important;
  color: white;
}

.customCard {
  width: 100%;
  height: 60px;
  background: #edfde4;
  border-radius: 4px;
  color: #222;
  font-weight: bold;
  border: 1px solid #ccc;
  text-align: center;
}
.card1 {
  height: auto;
}
.font-big-bold {
  font-weight: bold;
  font-size: 16px;
  padding: 20px 15px;
}
.customCard p {
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
}
.val {
  color: blue;
}
.emptyVal {
  color: red;
}

#stopContinueSubscribe,
#delivarData1,#delivarData2,#delivarData3,#delivarData4,#delivarData5 {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#stopContinueSubscribe td,
#delivarData1 td,#delivarData2 td,#delivarData3 td, #delivarData4 td,
#delivarData5 td,
#stopContinueSubscribe th,
#delivarData1 th, #delivarData2 th, #delivarData3 th, #delivarData4 th,
#delivarData5 th
  {
  border: 1px solid #ccc !important;
  padding: 8px;
  text-align: center;
}

#stopContinueSubscribe th,
#delivarData1 th, #delivarData2 th, #delivarData3 th, #delivarData4 th,
#delivarData5 th
{
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04aa6d;
  color: white;
}

#delivarData1 td, #delivarData2 td, #delivarData3 td, #delivarData4 td    {
  padding: 40px;
}

 #delivarData5 th {
  padding: 8px;
 }
  #delivarData5 td {
    padding: 8px;
  }
</style>